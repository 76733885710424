import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import { swiperOnResize } from '../utils/swiperOnResize.utils.js';

let trustedSwiperWr = document.querySelectorAll('.trusted-swiper-wr');
trustedSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');

    if(!el.classList.contains('trusted-section__slider-second')) {
      swiperOnResize('(max-width: 951px)', swiperEl, {
        modules: [Autoplay, Navigation],
        slidesPerView: 1,
        spaceBetween: 12,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: false,
        },
        breakpoints: {
          500: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 2.5,
            spaceBetween: 20,
          },
        },
        navigation: {
          prevEl: prevEl,
          nextEl: nextEl,
        },
      });
    } else {
      let swiper = new Swiper(swiperEl, {
        modules: [Autoplay, Navigation],
        slidesPerView: 1,
        spaceBetween: 20,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: false,
        },
        breakpoints: {
          500: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 2.5,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3.1,
          }
        },
        navigation: {
          prevEl: prevEl,
          nextEl: nextEl,
        },
      });
    }

  }
});
