const doubleRangeFirst = document.getElementsByClassName(
  'double-range-tooltips-1'
)[0];
if (doubleRangeFirst) {
  const slider = doubleRangeFirst.querySelector('#double-range-tooltips-1');
  const max = +slider.dataset.max;
  const min = +slider.dataset.min;
  const unit = slider.dataset?.unit || '';
  const step = +slider.dataset.step;
  const inputsHidden = doubleRangeFirst.querySelectorAll(
    '.double-range-hidden-input'
  );
  const startValueMin = +inputsHidden[0].value;
  const startValueMax = +inputsHidden[1].value;

  // how many percentages limit from borders ???? is 8%
  const borderLimit = 8;

  // each step is go backward for this amount of % ???? is 5%
  const borderDiff = 40 / borderLimit;

  noUiSlider.create(slider, {
    start: [startValueMin, startValueMax],
    connect: true,
    tooltips: true,
    margin: 10,
    step: step,
    range: {
      min: min,
      max: max,
    },
  });

  const tooltipsArr = slider.querySelectorAll('.noUi-tooltip');
  const circlesArr = slider.querySelectorAll('.noUi-origin');

  function returnTransform(element) {
    return element
      .replace(/[^0-9][^\d.]/g, '')
      .replace(')', '')
      .split(' ')
      .map((str) => {
        return Number(str);
      });
  }

  function needToMerge() {
    let tooltipOnePosition = tooltipsArr[0].getBoundingClientRect();
    let tooltipTwoPosition = tooltipsArr[1].getBoundingClientRect();

    if (
      tooltipsArr[0].classList.contains('hidden') ||
      tooltipsArr[1].classList.contains('hidden')
    ) {
      return true;
    }

    return (
      tooltipOnePosition.left +
        tooltipOnePosition.width -
        tooltipTwoPosition.left >
      0
    );
  }

  function resetTooltips(values) {
    mergeDiff = null;
    tooltipsArr.forEach((elem, index) => {
      elem.textContent =
        unit + Math.round(values[index]).toLocaleString('en-GB');
      elem.classList.remove('hidden');
    });
  }

  let trueTooltip = false;
  let mergeDiff = null;

  slider.noUiSlider.on('update', function (values, handle) {
    // translate of noUISlider -> 0% is start, 100% is end
    let translate = returnTransform(circlesArr[handle].style.transform)[0];

    // min value of double range slider
    let valueMin = returnTransform(circlesArr[0].style.transform)[0];

    // max value of double range slider
    let valueMax = returnTransform(circlesArr[1].style.transform)[0];

    // difference between min and max value of double range slider
    let difference = valueMax - valueMin;

    inputsHidden[handle].value = Math.round(values[handle]);

    // if tooltips are close to each other
    if (needToMerge()) {
      if (
        !tooltipsArr[+!handle].classList.contains('hidden') &&
        !tooltipsArr[handle].classList.contains('hidden')
      ) {
        trueTooltip = handle;
        mergeDiff = difference;
        tooltipsArr[+!handle].classList.add('hidden');
      }

      if (trueTooltip) {
        // limit left merged tooltip from overflowing
        // borderLimit * 3 === need for 3 times faster limitation because of merged tooltip
        if (translate <= -100 + borderLimit * 3) {
          tooltipsArr[trueTooltip].style.transform = `translate(${
            -50 -
            difference +
            (Math.abs(translate + 100 - borderLimit * 3) * borderDiff) / 2.5
          }%,100%)`;
        } else {
          // position of tooltip in the middle of range
          tooltipsArr[trueTooltip].style.transform = `translate(${
            -50 - difference
          }%,100%)`;
        }
      } else {
        // if left tooltip is grabbed
        if (translate >= -borderLimit * 4) {
          // limit right merged tooltip from overflowing
          tooltipsArr[trueTooltip].style.transform = `translate(${
            -50 +
            difference -
            (Math.abs(translate + borderLimit * 3) * borderDiff) / 2.5
          }%,100%)`;
        } else {
          tooltipsArr[trueTooltip].style.transform = `translate(${
            -50 + difference
          }%,100%)`;
        }
      }

      tooltipsArr[trueTooltip].textContent = `${
        unit + Math.round(values[0]).toLocaleString('en-GB')
      } - ${unit + Math.round(values[1]).toLocaleString('en-GB')}`;

      if (mergeDiff - difference < 0) {
        mergeDiff = null;
        resetTooltips(values);
      }
    } else {
      // limit left solo tooltip from overflowing
      if (translate <= -100 + borderLimit) {
        tooltipsArr[0].style.transform = `translate(${
          -50 + Math.abs(translate + 100 - borderLimit) * borderDiff
        }%,100%)`;
      } else if (translate >= -borderLimit) {
        // limit right solo tooltip from overflowing
        tooltipsArr[1].style.transform = `translate(${
          -50 - Math.abs(translate + borderLimit) * borderDiff
        }%,100%)`;
      } else {
        // position of tooltip in the middle of range
        tooltipsArr[handle].style.transform = 'translate(-50%,100%)';
      }

      tooltipsArr[handle].textContent = `${
        unit + Math.round(values[handle]).toLocaleString('en-GB')
      }`;
    }
  });
}

const doubleRangeSecond = document.getElementsByClassName(
  'double-range-tooltips-2'
)[0];
if (doubleRangeSecond) {
  const slider = doubleRangeSecond.querySelector('#double-range-tooltips-2');
  const max = +slider.dataset.max;
  const min = +slider.dataset.min;
  const unit = slider.dataset?.unit || '';
  const step = +slider.dataset.step;
  const inputsHidden = doubleRangeSecond.querySelectorAll(
    '.double-range-hidden-input'
  );
  const startValueMin = +inputsHidden[0].value;
  const startValueMax = +inputsHidden[1].value;

  // how many percentages limit from borders ???? is 8%
  const borderLimit = 8;

  // each step is go backward for this amount of % ???? is 5%
  const borderDiff = 40 / borderLimit;

  noUiSlider.create(slider, {
    start: [startValueMin, startValueMax],
    connect: true,
    tooltips: true,
    margin: 10,
    step: step,
    range: {
      min: min,
      max: max,
    },
  });

  const tooltipsArr = slider.querySelectorAll('.noUi-tooltip');
  const circlesArr = slider.querySelectorAll('.noUi-origin');

  function returnTransformSecond(element) {
    return element
      .replace(/[^0-9][^\d.]/g, '')
      .replace(')', '')
      .split(' ')
      .map((str) => {
        return Number(str);
      });
  }

  function needToMergeSecond() {
    let tooltipOnePosition = tooltipsArr[0].getBoundingClientRect();
    let tooltipTwoPosition = tooltipsArr[1].getBoundingClientRect();

    if (
      tooltipsArr[0].classList.contains('hidden') ||
      tooltipsArr[1].classList.contains('hidden')
    ) {
      return true;
    }

    return (
      tooltipOnePosition.left +
        tooltipOnePosition.width -
        tooltipTwoPosition.left >
      0
    );
  }

  function resetTooltipsSecond(values) {
    mergeDiff = null;
    tooltipsArr.forEach((elem, index) => {
      elem.textContent =
        unit + Math.round(values[index]).toLocaleString('en-GB');
      elem.classList.remove('hidden');
    });
  }

  let trueTooltip = false;
  let mergeDiff = null;

  slider.noUiSlider.on('update', function (values, handle) {
    // translate of noUISlider -> 0% is start, 100% is end
    let translate = returnTransformSecond(
      circlesArr[handle].style.transform
    )[0];

    // min value of double range slider
    let valueMin = returnTransformSecond(circlesArr[0].style.transform)[0];

    // max value of double range slider
    let valueMax = returnTransformSecond(circlesArr[1].style.transform)[0];

    // difference between min and max value of double range slider
    let difference = valueMax - valueMin;

    inputsHidden[handle].value = Math.round(values[handle]);

    // if tooltips are close to each other
    if (needToMergeSecond()) {
      if (
        !tooltipsArr[+!handle].classList.contains('hidden') &&
        !tooltipsArr[handle].classList.contains('hidden')
      ) {
        trueTooltip = handle;
        mergeDiff = difference;
        tooltipsArr[+!handle].classList.add('hidden');
      }

      if (trueTooltip) {
        // limit left merged tooltip from overflowing
        // borderLimit * 3 === need for 3 times faster limitation because of merged tooltip
        if (translate <= -100 + borderLimit * 3) {
          tooltipsArr[trueTooltip].style.transform = `translate(${
            -50 -
            difference +
            (Math.abs(translate + 100 - borderLimit * 3) * borderDiff) / 2.5
          }%,100%)`;
        } else {
          // position of tooltip in the middle of range
          tooltipsArr[trueTooltip].style.transform = `translate(${
            -50 - difference
          }%,100%)`;
        }
      } else {
        // if left tooltip is grabbed
        if (translate >= -borderLimit * 4) {
          // limit right merged tooltip from overflowing
          tooltipsArr[trueTooltip].style.transform = `translate(${
            -50 +
            difference -
            (Math.abs(translate + borderLimit * 3) * borderDiff) / 2.5
          }%,100%)`;
        } else {
          tooltipsArr[trueTooltip].style.transform = `translate(${
            -50 + difference
          }%,100%)`;
        }
      }

      tooltipsArr[trueTooltip].textContent = `${
        unit + Math.round(values[0]).toLocaleString('en-GB')
      } - ${unit + Math.round(values[1]).toLocaleString('en-GB')}`;

      if (mergeDiff - difference < 0) {
        mergeDiff = null;
        resetTooltipsSecond(values);
      }
    } else {
      // limit left solo tooltip from overflowing
      if (translate <= -100 + borderLimit) {
        tooltipsArr[0].style.transform = `translate(${
          -50 + Math.abs(translate + 100 - borderLimit) * borderDiff
        }%,100%)`;
      } else if (translate >= -borderLimit) {
        // limit right solo tooltip from overflowing
        tooltipsArr[1].style.transform = `translate(${
          -50 - Math.abs(translate + borderLimit) * borderDiff
        }%,100%)`;
      } else {
        // position of tooltip in the middle of range
        tooltipsArr[handle].style.transform = 'translate(-50%,100%)';
      }

      tooltipsArr[handle].textContent = `${
        unit + Math.round(values[handle]).toLocaleString('en-GB')
      }`;
    }
  });
}

const doubleRangeThird = document.getElementsByClassName(
  'double-range-tooltips-3'
)[0];
if (doubleRangeThird) {
  const slider = doubleRangeThird.querySelector('#double-range-tooltips-3');
  const max = +slider.dataset.max;
  const min = +slider.dataset.min;
  const unit = slider.dataset?.unit || '';
  const step = +slider.dataset.step;
  const inputsHidden = doubleRangeThird.querySelectorAll(
    '.double-range-hidden-input'
  );
  const startValueMin = +inputsHidden[0].value;
  const startValueMax = +inputsHidden[1].value;

  // how many percentages limit from borders ???? is 8%
  const borderLimit = 8;

  // each step is go backward for this amount of % ???? is 5%
  const borderDiff = 40 / borderLimit;

  noUiSlider.create(slider, {
    start: [startValueMin, startValueMax],
    connect: true,
    tooltips: true,
    margin: 10,
    step: step,
    range: {
      min: min,
      max: max,
    },
  });

  const tooltipsArr = slider.querySelectorAll('.noUi-tooltip');
  const circlesArr = slider.querySelectorAll('.noUi-origin');

  function returnTransformThird(element) {
    return element
      .replace(/[^0-9][^\d.]/g, '')
      .replace(')', '')
      .split(' ')
      .map((str) => {
        return Number(str);
      });
  }

  function needToMergeThird() {
    let tooltipOnePosition = tooltipsArr[0].getBoundingClientRect();
    let tooltipTwoPosition = tooltipsArr[1].getBoundingClientRect();

    if (
      tooltipsArr[0].classList.contains('hidden') ||
      tooltipsArr[1].classList.contains('hidden')
    ) {
      return true;
    }

    return (
      tooltipOnePosition.left +
        tooltipOnePosition.width -
        tooltipTwoPosition.left >
      0
    );
  }

  function resetTooltipsThird(values) {
    mergeDiff = null;
    tooltipsArr.forEach((elem, index) => {
      elem.textContent =
        unit + Math.round(values[index]).toLocaleString('en-GB');
      elem.classList.remove('hidden');
    });
  }

  let trueTooltip = false;
  let mergeDiff = null;

  slider.noUiSlider.on('update', function (values, handle) {
    // translate of noUISlider -> 0% is start, 100% is end
    let translate = returnTransformThird(circlesArr[handle].style.transform)[0];

    // min value of double range slider
    let valueMin = returnTransformThird(circlesArr[0].style.transform)[0];

    // max value of double range slider
    let valueMax = returnTransformThird(circlesArr[1].style.transform)[0];

    // difference between min and max value of double range slider
    let difference = valueMax - valueMin;

    inputsHidden[handle].value = Math.round(values[handle]);

    // if tooltips are close to each other
    if (needToMergeThird()) {
      if (
        !tooltipsArr[+!handle].classList.contains('hidden') &&
        !tooltipsArr[handle].classList.contains('hidden')
      ) {
        trueTooltip = handle;
        mergeDiff = difference;
        tooltipsArr[+!handle].classList.add('hidden');
      }

      if (trueTooltip) {
        // limit left merged tooltip from overflowing
        // borderLimit * 3 === need for 3 times faster limitation because of merged tooltip
        if (translate <= -100 + borderLimit * 3) {
          tooltipsArr[trueTooltip].style.transform = `translate(${
            -50 -
            difference +
            (Math.abs(translate + 100 - borderLimit * 3) * borderDiff) / 2.5
          }%,100%)`;
        } else {
          // position of tooltip in the middle of range
          tooltipsArr[trueTooltip].style.transform = `translate(${
            -50 - difference
          }%,100%)`;
        }
      } else {
        // if left tooltip is grabbed
        if (translate >= -borderLimit * 4) {
          // limit right merged tooltip from overflowing
          tooltipsArr[trueTooltip].style.transform = `translate(${
            -50 +
            difference -
            (Math.abs(translate + borderLimit * 3) * borderDiff) / 2.5
          }%,100%)`;
        } else {
          tooltipsArr[trueTooltip].style.transform = `translate(${
            -50 + difference
          }%,100%)`;
        }
      }

      tooltipsArr[trueTooltip].textContent = `${
        unit + Math.round(values[0]).toLocaleString('en-GB')
      } - ${unit + Math.round(values[1]).toLocaleString('en-GB')}`;

      if (mergeDiff - difference < 0) {
        mergeDiff = null;
        resetTooltipsThird(values);
      }
    } else {
      // limit left solo tooltip from overflowing
      if (translate <= -100 + borderLimit) {
        tooltipsArr[0].style.transform = `translate(${
          -50 + Math.abs(translate + 100 - borderLimit) * borderDiff
        }%,100%)`;
      } else if (translate >= -borderLimit) {
        // limit right solo tooltip from overflowing
        tooltipsArr[1].style.transform = `translate(${
          -50 - Math.abs(translate + borderLimit) * borderDiff
        }%,100%)`;
      } else {
        // position of tooltip in the middle of range
        tooltipsArr[handle].style.transform = 'translate(-50%,100%)';
      }

      tooltipsArr[handle].textContent = `${
        unit + Math.round(values[handle]).toLocaleString('en-GB')
      }`;
    }
  });
}

const singleRangeSlider = () => {
  const singleRange = document.getElementsByClassName(
    'single-range-default'
  )[0];
  if (singleRange) {
    const slider = singleRange.querySelector('#single-range-default');
    const inputsHidden = singleRange.querySelector(
      '.single-range-hidden-input'
    );
    const inputInfo = singleRange.querySelector('.single-range-info');
    const unit = slider.dataset?.unit || '';

    const fixedPoints = [
      10, 20, 30, 40, 50, 75, 100, 250, 500, 1000, 2500, 5000,
    ];

    let isUpdating = false;

    noUiSlider.create(slider, {
      start: [fixedPoints[0]],
      connect: 'lower',
      range: {
        min: fixedPoints[0],
        max: fixedPoints[fixedPoints.length - 1],
      },
      step: 1,
      pips: {
        mode: 'positions',
        values: fixedPoints.map(
          (_, index) => (index / (fixedPoints.length - 1)) * 100
        ),
        density: 4,
      },
    });

    // Привязка к ближайшему значению
    slider.noUiSlider.on('update', function (values, handle) {
      if (isUpdating) return;

      const currentValue = +values[handle];
      const closestValue = fixedPoints.reduce((prev, curr) =>
        Math.abs(curr - currentValue) < Math.abs(prev - currentValue)
          ? curr
          : prev
      );

      if (currentValue !== closestValue) {
        isUpdating = true;
        slider.noUiSlider.set(closestValue);
        isUpdating = false;
      }

      if (inputInfo) {
        inputInfo.textContent = `Radius: ${closestValue} ${unit}`;
      }

      if (inputsHidden) {
        inputsHidden.value = closestValue;
      }
    });
  }
};

singleRangeSlider();

if (document.querySelector('.range-checkbox')) {
  const rangeCheckboxes = document.querySelectorAll('.range-checkbox');
  rangeCheckboxes.forEach((rangeCheckbox) => {
    const range = rangeCheckbox.nextElementSibling;
    const checkbox = rangeCheckbox.querySelector('input');

    checkbox.addEventListener('click', () => {
      if (checkbox.checked) {
        range.classList.add('is-open');
      } else {
        range.classList.remove('is-open');
      }
    });

    if (checkbox.checked) {
      range.classList.add('is-open');
    } else {
      range.classList.remove('is-open');
    }
  });
}
