import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('team-section__slider')[0]) {
  const teamsSlidersArr = document.querySelectorAll('.team-section__slider');
  teamsSlidersArr.forEach((sliderEl, sliderIdx) => {
    const slides = sliderEl.querySelectorAll('.swiper-slide');
    /*let loop = false;
    let loopedSlides = false;*/

    /*if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    let swiper = new Swiper(sliderEl.querySelector('.swiper'), {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 20,
      threshold: 10,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      navigation: {
        prevEl: sliderEl.querySelector('.swiper-button-prev'),
        nextEl: sliderEl.querySelector('.swiper-button-next'),
      },
      breakpoints: {
        551: {
          spaceBetween: 16,
          slidesPerView: 2,
        },
        651: {
          spaceBetween: 20,
          slidesPerView: 2,
        },
        901: {
          spaceBetween: 16,
          slidesPerView: 3,
        },
        1025: {
          spaceBetween: 20,
          slidesPerView: 3,
        },
        1100: {
          spaceBetween: 30,
          slidesPerView: 4,
        },
      },
    });
    swiperObserver(swiper);

    function setMinWidth() {
      slides.forEach((slide) => {
        const width = slide.style.width;
        slide.style.minWidth = width;
      });
    }

    setTimeout(function () {
      setMinWidth();
    }, 250);

    window.addEventListener('resize', setMinWidth);
  });
}
