window.saveBtn = function saveBtn(btn) {
  const isSecondSaveBtn = btn.classList.contains('btn-second-save');

  if (isSecondSaveBtn) {
    const isActive = btn.classList.contains('active');

    const allSaveButtons = document.querySelectorAll('.btn-second-save');

    allSaveButtons.forEach((saveBtn) => {
      if (isActive) {
        saveBtn.classList.remove('active');
      } else {
        saveBtn.classList.add('active');
      }

      const btnSecondArr = saveBtn.querySelectorAll('.btn-second-toggle');
      btnSecondArr.forEach((btnSecond) => {
        const icon = btnSecond.querySelector('.btn__icon');
        if (isActive) {
          btnSecond.classList.remove('active');
          if (icon) icon.classList.remove('active');
        } else {
          btnSecond.classList.add('active');
          if (icon) icon.classList.add('active');
        }
      });
    });
  } else {
    const icon = btn.querySelector('.btn__icon');
    if (icon) {
      icon.classList.toggle('active');
    }

    const iconSecond = btn.querySelector('.text-btn__icon');
    if (iconSecond) {
      iconSecond.classList.toggle('active');
    }
  }
};
