const portalComponent = document.querySelector('.profile');

if (portalComponent) {
  const portalContainer = portalComponent.querySelector('.profile__wrap');
  const portalMenuBtn = portalComponent.querySelector('.profile__filters-btn');

  portalMenuBtn.addEventListener('click', () => {
    if (!portalMenuBtn.classList.contains('active')) {
      portalMenuBtn.classList.add('active');
      portalContainer.classList.add('active');
    } else {
      portalMenuBtn.classList.remove('active');
      portalContainer.classList.remove('active');
    }
  });
}
