import Swiper, { Navigation, Autoplay } from 'swiper';
import { swiperOnResize } from '../utils/swiperOnResize.utils.js';

let trustedSwiperWr = document.querySelectorAll('.our-values-swiper-wr');
trustedSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');


    swiperOnResize('(max-width: 992px)', swiperEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 12,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      breakpoints: {
        // >=
        500: {
          slidesPerView: 1.8,
        },
        768: {
          slidesPerView: 2,
        },
        820: {
          slidesPerView: 2.5,
        },
      },
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
    });


  }
});
