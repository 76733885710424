import Swiper, { Autoplay, Navigation } from 'swiper';

let clientsSaySwiperWr = document.querySelectorAll('.logos-swiper-wr');
clientsSaySwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let parentEl = el.parentElement;
    let nextEl = parentEl.querySelector('.swiper-button-next');
    let prevEl = parentEl.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');

    let swiper = new Swiper(swiperEl, {
      modules: [Navigation, Autoplay],
      spaceBetween: 12,
      slidesPerView: 1.75,
      loop: slidesCount > 2,
      centeredSlides: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
        pauseOnMouseEnter: false,
      },
      breakpoints: {
        500: {
          slidesPerView: 2.2,
        },
        700: {
          slidesPerView: 'auto',
        },
        768: {
          spaceBetween: 20,
          slidesPerView: 'auto',
        },
      },
    });
  }
});
