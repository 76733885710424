if (document.querySelector('.contact-form-section__toggle-btn')) {
  const toggleButtons = document.querySelectorAll('.contact-form-section__toggle-btn');
  const toggleBg = document.querySelector('.contact-form-section__toggle-bg');
  const tabContents = document.querySelectorAll('.contact-form-section__form');

  toggleButtons?.forEach((item) => {
    item.addEventListener('click', () => {
      toggleButtons.forEach((btn) => {
        btn.classList.remove('contact-form-section__toggle-btn--active');
      });
      item.classList.add('contact-form-section__toggle-btn--active');

      if (item.classList.contains('contact-form-section__toggle-2')) {
        toggleBg.classList.add('contact-form-section__toggle-bg-2');
      } else {
        toggleBg.classList.remove('contact-form-section__toggle-bg-2');
      }

      let tabId = item.getAttribute('data-contact-tab');

      tabContents.forEach(function (content) {
        content.classList.remove('contact-form-section__form--active');
      });

      const activeForm = document.getElementById('contact-form-' + tabId);
      activeForm.classList.add('contact-form-section__form--active');

      if (window.innerWidth <= 992) {
        activeForm.scrollIntoView({ behavior: 'smooth' });
      }
    });
  });

  // toggleButtons[0].classList.add('contact-form-section__toggle-btn--active');
  // tabContents[0].classList.add('contact-form-section__form--active');
}
