let tabs = document.querySelectorAll('.our-location-section__tab');
let tabContents = document.querySelectorAll('.our-location-section__content');

tabs?.forEach(function (tab) {
    tab.addEventListener('click', function () {
        let tabId = this.getAttribute('data-tab-location');

        tabs.forEach(function (tab) {
        tab.classList.remove('our-location-section__tab--active');
        });

        tabContents.forEach(function (content) {
        content.classList.remove('our-location-section__content--active');
        });

        this.classList.add('our-location-section__tab--active');
        const activeContent = document.getElementById('our-location-' + tabId);
        activeContent.classList.add('our-location-section__content--active');

        if (window.innerWidth < 992) {
        activeContent.scrollIntoView({ behavior: 'smooth' });
        }
    });
});

// Активуємо перший таб і його контент за замовчуванням
tabs[0]?.classList.add('our-location-section__tab--active');
tabContents[0]?.classList.add('our-location-section__content--active');