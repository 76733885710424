import Swiper, { Autoplay, Navigation , Pagination} from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let clientsSaySwiperWr = document.querySelectorAll('.bulettins-slider-swiper-wr');
clientsSaySwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let parentEl = el.parentElement;
    let nextEl = parentEl.querySelector('.swiper-button-next');
    let prevEl = parentEl.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, Pagination],
      observer: true, //n-eeds to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      spaceBetween: 10,
      slidesPerView: 1,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      pagination: {
        el: ".swiper-pagination",
        type: "fraction",
        formatFractionCurrent: function (number) {
          return number
        },
        formatFractionTotal: function (number) {
          return number
        },
      },
    });
    /*swiperObserver(swiper);*/
  }
});
