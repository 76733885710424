// accordion

const accordions = document.querySelectorAll(
  '.our-services-section__accordion'
);

const closeAllAccordions = () => {
  accordions.forEach((accordion) => {
    const bodyAccordion = accordion.querySelector(
      '.our-services-section__accordion-body'
    );
    accordion.classList.remove('our-services-section__accordion--active');
    bodyAccordion.style.maxHeight = '0px';
  });
};

const openFirstAccordion = () => {
  if (accordions.length > 0) {
    const firstAccordion = accordions[0];
    const bodyAccordion = firstAccordion?.querySelector(
      '.our-services-section__accordion-body'
    );
    firstAccordion.classList?.add('our-services-section__accordion--active');
    bodyAccordion.style.maxHeight = `${bodyAccordion.scrollHeight + 120}px`;
  }
};

accordions.forEach((accordion) => {
  const navAccordion = accordion.querySelector(
    '.our-services-section__accordion-nav'
  );
  const bodyAccordion = accordion.querySelector(
    '.our-services-section__accordion-body'
  );
  const accrodionParen = bodyAccordion.parentElement;

  const toggleAccordion = () => {
    if (
      !accordion.classList.contains('our-services-section__accordion--active')
    ) {
      closeAllAccordions();
    }

    accordion.classList.toggle('our-services-section__accordion--active');
    if (
      accordion.classList.contains('our-services-section__accordion--active')
    ) {
      bodyAccordion.style.maxHeight = `${bodyAccordion.scrollHeight + 120}px`;
    } else {
      bodyAccordion.style.maxHeight = '0px';
    }
  };

  navAccordion.addEventListener('click', toggleAccordion);
  window.addEventListener('resize', () => {
    if (
      accordion.classList.contains('our-services-section__accordion--active')
    ) {
      bodyAccordion.style.maxHeight = `${bodyAccordion.scrollHeight + 120}px`;
      console.log('resize');
    }
  });
});

openFirstAccordion();
