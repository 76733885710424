import Swiper, { Autoplay, Grid, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let clientsSwiperWr = document.querySelectorAll('.clients-swiper-wr');
clientsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, Grid],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      slidesPerView: 2,
      spaceBetween: 16,
      grid: {
        rows: 2,
      },
      threshold: 10,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        421: {
          slidesPerView: 2,
          spaceBetween: 18,
          grid: {
            rows: 2,
          },
        },
        652: {
          slidesPerView: 3,
          spaceBetween: 16,
          grid: {
            rows: 2,
          },
        },
        902: {
          slidesPerView: 4,
          spaceBetween: 16,
          grid: {
            rows: 2,
          },
        },
        1100: {
          slidesPerView: 4,
          spaceBetween: 20,
          grid: {
            rows: 2,
          },
        },
      },
    });
    swiperObserver(swiper);
  }
});
