import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('services-section__slider')[0]) {
  const servicesSlidersArr = document.querySelectorAll(
    '.services-section__slider'
  );
  servicesSlidersArr.forEach((sliderEl, sliderIdx) => {
    const pagination = sliderEl.querySelector('.swiper-pagination');
    /*const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    let pauseOnMouseEnter = window.innerWidth > 1024;

    const servicesSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation, Pagination],
      slidesPerView: 1,
      spaceBetween: 20,
      threshold: 10,
      /*loop: loop,
      loopedSlides: loopedSlides,*/
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      pagination: {
        el: pagination,
        clickable: true,
      },
      navigation: {
        prevEl: sliderEl.querySelector('.slider-buttons .slider-btn.prev'),
        nextEl: sliderEl.querySelector('.slider-buttons .slider-btn.next'),
      },
      breakpoints: {
        451: {
          spaceBetween: 20,
          slidesPerView: 2,
        },
        651: {
          spaceBetween: 20,
          slidesPerView: 3,
        },
        901: {
          spaceBetween: 30,
          slidesPerView: 3,
        },
        1100: {
          spaceBetween: 16,
          slidesPerView: 4,
        },
        1200: {
          spaceBetween: 30,
          slidesPerView: 4,
        },
      },
    });

    swiperObserver(servicesSwiper);
  });
}


