import marquee from 'vanilla-marquee/dist/vanilla-marquee.js';

/*let speedMarque = 20;

if (document.getElementById('marquee_1')) {
  if (window.innerWidth <= 640) {
    speedMarque = 10;
  }
  new marquee(document.getElementById('marquee_1'), {
    duplicated: true,
    gap: 12,
    speed: speedMarque,
    pauseOnHover: true,
    recalcResize: false,
    startVisible: true,
  });
}

if (document.getElementById('marquee_2')) {
  new marquee(document.getElementById('marquee_2'), {
    duplicated: true,
    gap: 12,
    speed: speedMarque,
    pauseOnHover: true,
    direction: 'right',
    recalcResize: false,
    startVisible: true,
  });
}*/

if (document.querySelector('.clients__marquee')) {
  const marqueeArray = [];

  const marqueeArr = document.querySelectorAll('.clients__marquee');
  marqueeArr.forEach((marqueeEl, i) => {
    const itemsArr = marqueeEl.querySelectorAll('.clients__logo');
    itemsArr.forEach((itemEl) => {
      const clone = itemEl.cloneNode(true);
      marqueeEl.appendChild(clone);
    });
    itemsArr.forEach((itemEl) => {
      const clone = itemEl.cloneNode(true);
      marqueeEl.appendChild(clone);
    });
    itemsArr.forEach((itemEl) => {
      const clone = itemEl.cloneNode(true);
      marqueeEl.appendChild(clone);
    });

    const container = 1440;

    const finalItemsArr = marqueeEl.querySelectorAll('.clients__logo');

    let duration = 5800;

    const totalWidth =
      finalItemsArr.length * (finalItemsArr[0].clientWidth + 12);
    let baseSpeed = 4;

    let scalingFactor = window.innerWidth < 768 ? 1.1 : 1.5;

    if (window.innerWidth >= 1921) {
      scalingFactor = 2.5;
    }

    baseSpeed *= scalingFactor;

    duration = (totalWidth / window.innerWidth) * baseSpeed;

    let direction = 'up';
    let pauseOnHover = true;

    if (window.innerWidth <= 1024) {
      pauseOnHover = false;
    }

    direction = 'left';
    if (i % 2) {
      direction = 'right';
    }

    const mq = new marquee(marqueeEl, {
      delayBeforeStart: 0,
      direction: direction,
      pauseOnHover: pauseOnHover,
      duplicated: true,
      recalcResize: window.innerWidth >= 768,
      startVisible: true,
      gap: 12,
      duration: duration * 1000,
    });

    marqueeArray.push(mq);

    if (window.innerWidth <= 1024) {
      setTimeout(function () {
        const itemsArr = marqueeEl.querySelectorAll('.clients__logo');
        itemsArr.forEach((item) => {
          item.addEventListener('click', (e) => {
            if (item.classList.contains('is-hover')) {
              item.classList.remove('is-hover');
              mq.resume();
            } else {
              const newArr = document.querySelectorAll('.clients__logo');
              for (let j = 0; j < newArr.length; j++) {
                newArr[j].classList.remove('is-hover');
              }
              item.classList.add('is-hover');
              for (let j = 0; j < marqueeArray.length; j++) {
                marqueeArray[j].resume();
              }
              mq.pause();
            }
          });
        });
      }, 100);
    }
  });

  document.addEventListener('click', function (event) {
    if (!marqueeArr[0].parentElement.contains(event.target)) {
      const newArr = document.querySelectorAll('.clients__logo');
      for (let j = 0; j < newArr.length; j++) {
        newArr[j].classList.remove('is-hover');
      }
      for (let j = 0; j < marqueeArray.length; j++) {
        marqueeArray[j].resume();
      }
    }
  });
}
