import Swiper, { Autoplay, EffectCreative, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let historySwiperWr = document.querySelectorAll('.history-swiper-wr');
historySwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, EffectCreative],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      spaceBetween: 20,
      slidesPerView: 1,
      speed: 800,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      effect: 'creative',
      creativeEffect: {
        limitProgress: 3,
        prev: {
          translate: [0, 0, -320],
          opacity: 0,
          effect: 'scale',
          scale: 0.6,
        },
        next: {
          /*translate: ['calc(100% - 20px)', 0, 0],*/
          translate: ['calc(45% + 20px)', 0, 0],
          opacity: 1,
          effect: 'scale',
          scale: 0.8,
        },
      },
      breakpoints: {
        851: {
          creativeEffect: {
            limitProgress: 3,
            prev: {
              translate: [0, 0, -320],
              opacity: 0,
              effect: 'scale',
              scale: 0.6,
            },
            next: {
              /*translate: ['calc(100% - 20px)', 0, 0],*/
              translate: ['calc(60% + 20px)', 0, 0],
              opacity: 1,
              effect: 'scale',
              scale: 0.8,
            },
          },
        },
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
    });
    /*swiperObserver(swiper);*/
  }
});
