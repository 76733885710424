import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let clientsSaySwiperWr = document.querySelectorAll('.clients-say-swiper-wr');
clientsSaySwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let parentEl = el.parentElement;
    let nextEls = Array.from(el.querySelectorAll('.swiper-button-next'));
    let prevEls = Array.from(el.querySelectorAll('.swiper-button-prev'));
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');

    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      spaceBetween: 20,
      slidesPerView: 1,
      threshold: 10,
      navigation: {
        nextEl: nextEls,
        prevEl: prevEls,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      breakpoints: {
        640: {
          spaceBetween: 16,
          slidesPerView: 1,
        },
        768: {
          spaceBetween: 20,
          slidesPerView: 1,
        },
        1024: {
          spaceBetween: 16,
          slidesPerView: 1,
        },
        1200: {
          spaceBetween: 20,
          slidesPerView: 1,
        },
      },
      on: {
        afterInit: function () {
          wrapper.scrollWidth >= wrapper.clientWidth
            ? wrapper.classList.remove('justify-center')
            : wrapper.classList.add('justify-center');
        },

        resize: function () {
          wrapper.scrollWidth >= wrapper.clientWidth
            ? wrapper.classList.remove('justify-center')
            : wrapper.classList.add('justify-center');
        },
      },
    });
    /*swiperObserver(swiper);*/
  }
});
