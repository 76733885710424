import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let clientsSaySwiperWr = document.querySelectorAll('.gallery-swiper-wr');
clientsSaySwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let parentEl = el.parentElement;
    let nextEl = parentEl.querySelector('.swiper-button-next');
    let prevEl = parentEl.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let reverse = swiperEl.getAttribute('data-reverseDirection') === 'true';

    console.log(reverse);
    let swiper = new Swiper(swiperEl, {
      modules: [Navigation, Autoplay],
      spaceBetween: 20,
      slidesPerView: 'auto',
      centeredSlides: true,
      loop: slidesCount > 2,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
        reverseDirection: reverse,
      },
      breakpoints: {
        768: {
          spaceBetween: 30,
          centeredSlides: false,
        },
      },
    });
  }
});
