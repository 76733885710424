document.querySelectorAll('.login-section__input-icon').forEach(icon => {
  icon.addEventListener('click', () => {
    const input = icon.previousElementSibling;
    if (input.type === 'password') {
      icon.classList.add('opacity-50')
      input.type = 'text';
    } else {
      icon.classList.remove('opacity-50')
      input.type = 'password';
    }
  });
});