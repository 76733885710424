const togetherList = document.querySelector('.together-section__list');
if (togetherList) {
  const togetherItemsArr = togetherList.querySelectorAll(
    '.together-section__item'
  );
  togetherItemsArr.forEach((item) => {
    item.addEventListener('click', (e) => {
      if (!item.classList.contains('active')) {
        for (let i = 0; i < togetherItemsArr.length; i++) {
          togetherItemsArr[i].classList.remove('active');
        }
        item.classList.add('active');
      }
    });
  });
}
